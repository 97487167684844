import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MIKOM_API } from "../utils/constants";

export const getCategory = createAsyncThunk(
  "category/getCategory",
  async (data) => {
    try {
      const response = await fetch(MIKOM_API, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Accept: "application/json",
        },
      });
      const categoryResponse = await response.json();
      // console.log("categoryResponse", categoryResponse);
      return categoryResponse;
    } catch (error) {
      console.log(" category thunk hatası " + error);
    }
  }
);

export const categorySlice = createSlice({
  name: "categories",
  initialState: {
    category: [],
    isLoading: false,
    hasError: false,
  },
  // extraReducers: {
  //   [getCategory.pending]: (state) => {
  //     state.isLoading = true;
  //   },
  //   [getCategory.fulfilled]: (state, action) => {
  //     state.category = action.payload;
  //     state.isLoading = false;
  //   },
  //   [getCategory.rejected]: (state) => {
  //     state.isLoading = false;
  //   },
  // },
  extraReducers: builder => {
    builder
      .addCase(getCategory.pending, state => {
        state.isLoading = true;
      })
      .addCase(getCategory.fulfilled, (state, action) => {
        state.category = action.payload;
        state.isLoading = false;
      })
      .addCase(getCategory.rejected, state => {
        state.isLoading = false;
      });
  },
});

export default categorySlice;
