import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from './App';
import Error from './pages/Error';
import Home from './pages/Home';
import FirstPage from './pages/FirstPage';
import ProductDetail from './pages/ProductDetail';
import Search from './pages/Search';
import store from './store/store';
import './styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { LanguageProvider } from './context/LanguageContext';
const queryParams = new URLSearchParams(window.location.search);
const id = queryParams.get("id");
// const id = 'MP331';
// const id = 'MP348';
// const id = 'MP356';



const router = createBrowserRouter([
  {
    path: '/',
    element: <App id={id} />,
    children: [
      {
        index: true,
        element: <FirstPage id={id} />
      },
      {
        path: 'home',
        element: <Home id={id} />
      },
    
      {
        path: 'product/:code',
        element: <ProductDetail id={id} />
      },
      {
        path: 'search',
        element: <Search id={id}/>
      }
    ],
    errorElement: <Error />
  }
]);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <LanguageProvider>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </LanguageProvider>
);
