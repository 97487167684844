import React, { useEffect, useState, useContext } from "react";
import { getRestaurant } from "../state/restaurantSlice";
import { useDispatch, useSelector } from "react-redux";
import { getCategory } from "../state/categorySlice";
import { Link } from "react-router-dom";
import facebook from "../assets/sosyal/facebook.png";
import instagram from "../assets/sosyal/instagram.png";
import twitterx from "../assets/sosyal/twitterx.png";
import mail from "../assets/sosyal/mail.png";
import down from "../assets/icon/down.png";
import "../styles/firstpage.styles.css";
import link from "../assets/sosyal/link.png";
import info from "../assets/icon/info.png";
import right from "../assets/icon/right.png";
import phone from "../assets/icon/phone.png";
import turkey from "../assets/icon/turkey.png";
import { LanguageContext } from "../context/LanguageContext";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { GlobeAltIcon } from "@heroicons/react/24/solid";
import english from "../assets/icon/english.png";
// import background from '../assets/images/piraye.jpg';
import {
  ChevronDownIcon,
  InformationCircleIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/solid";

const FirstPage = ({ id }) => {
  const { language, chooseLanguage } = useContext(LanguageContext);

  const [adresModal, setAdresModal] = useState(false);

  const [languageModal, setLanguageModal] = useState(false);

  const [menuModal, setMenuModal] = useState(false);

  const dispatch = useDispatch();

  const { category } = useSelector((state) => state.category);

  const { restaurant } = useSelector((state) => state.restaurant);
  const [resim1, setResim1] = useState("");

  useEffect(() => {
    const data2 = { path: id + "/categoryno.json" };
    const data = { path: id + "/restaurant.json" };
    dispatch(getCategory(data2));
    dispatch(getRestaurant(data));
  }, [dispatch, id]);

  const groupby = category?.reduce((group, category) => {
    const { UP_CATEGORY_TR, UP_CATEGORY_IN } = category;
    if (language === "Türkçe") {
      group[UP_CATEGORY_TR] = group[UP_CATEGORY_TR] ?? [];
      group[UP_CATEGORY_TR].push(category);
    } else {
      group[UP_CATEGORY_IN] = group[UP_CATEGORY_IN] ?? [];
      group[UP_CATEGORY_IN].push(category);
    }
    return group;
  }, {});
  // mp331
  // mbps3 1867
  //console.log("Object.keys(groupby)", Object.keys(groupby));

  const url =
    "https://1zw31qczi7.execute-api.eu-central-1.amazonaws.com/prod/restaurant_pictures/" +
    id +
    "/";

  useEffect(() => {
    if (id) {
      fetch(url + "1").then((data) => {
        data.text().then((text) => setResim1(text));
      });
    }
  }, [id]);
  // console.log(Object.keys(groupby)?.[0] )
  console.log(resim1)

  return (
    <div className="firstContainer">
      {resim1 ? (
        <>
          <img
            src={resim1}
            alt="bgImage"
            className="object-fill w-full h-full absolute inset-0 z--1 "
          />
          <div className="absolute top-0 left-0 w-full h-full bg-dark opacity-20"></div>

          <div className="centered z-20 mt-40">
            <h3 className="page-title text-white text-center">
              {restaurant[0]?.FIRMA}
            </h3>
            <div
              className="languageContainer tr_eng align-center items-center"
              onClick={() => setLanguageModal(true)}
            >
              <GlobeAltIcon className="w-6 h-6 mr-2 text-white" />

              <a href="#" className="text-white font-bold">
                {language === "Türkçe" && "Türkçe"}
              </a>
              <ChevronDownIcon className="w-6 h-6 mr-2 text-white ml-2" />
            </div>
            <div className="menu-container " onClick={() => setMenuModal(true)}>
              {language === "Türkçe" && (
                <button className="bg-teal-600 p-2 text-white rounded-lg">
                  Menüyü Gör
                </button>
              )}
            </div>
          </div>
          <div
            className="infoContainer z-20"
            onClick={() => setAdresModal(true)}
          >
            <div className="info-1">
              <InformationCircleIcon className="w-6 h-6  text-white" />
              {language === "Türkçe" && (
                <h6 className="text-white font-bold">Bilgi</h6>
              )}
            </div>
            <div className="info-2">
              <ChevronRightIcon className="w-6 h-6 text-white" />
            </div>
          </div>

          {/* adres modalı */}
          <Modal
            className="my-modal"
            centered
            size="md"
            isOpen={adresModal}
            toggle={() => setAdresModal(!adresModal)}
          >
            <ModalHeader toggle={() => setAdresModal(!adresModal)}>
              <p className="mt-3">Bilgi</p>
            </ModalHeader>
            <ModalBody>
              <div className="modal-container">
                <div className="address-body">
                  <p className="mt-3">{restaurant[0]?.FIRMA}</p>
                </div>
                <div className="adresContainer">
                  <p>ADRES</p>

                  <p className="text-sm">{restaurant[0]?.ADRES}</p>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="btn btn-outline-secondary"
                onClick={() => setAdresModal(!adresModal)}
              >
                Kapat
              </Button>
            </ModalFooter>
          </Modal>

          {/* language modal */}
          <Modal
            className="my-modal"
            centered
            size="md"
            isOpen={languageModal}
            toggle={() => setLanguageModal(!languageModal)}
          >
            <ModalHeader toggle={() => setLanguageModal(!languageModal)}>
              <p className="langTitle mt-3">{language}</p>
            </ModalHeader>
            <ModalBody>
              <div className="modal-container">
                <div
                  className="language-body"
                  onClick={() =>
                    chooseLanguage("Türkçe") || setLanguageModal(false)
                  }
                >
                  <img src={turkey} alt="tr" className="turkey-icon" />
                  <p className="lang">Türkçe</p>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              
                <Button
                  color="btn btn-outline-secondary"
                  onClick={() => setLanguageModal(!languageModal)}
                >
                  Kapat
                </Button>
     
            </ModalFooter>
          </Modal>

          {/* menu modal */}

          <Modal
            className="my-modal"
            centered
            size="md"
            isOpen={menuModal}
            toggle={() => setMenuModal(!menuModal)}
          >
            <ModalHeader toggle={() => setMenuModal(!menuModal)}>
              {language === "Türkçe" && <p className="mt-3">Menü Seçin</p>}
            </ModalHeader>
            <ModalBody>
              <div className="modalmenu-container">
                {!!Object.keys(groupby)?.[0] &&
                  Object.keys(groupby)?.map((item, index) => {
                    return (
                      <Link to={`/home?selectedCategory=${item}`}>
                        <div
                          key={index}
                          className="border-b d-flex flex-row justify-between items-center"
                        >
                          <p className="p-1 mt-3">{item}</p>
                          <img
                            src={right}
                            className="right-logo w-5 h-5"
                            alt="img"
                          />
                        </div>
                      </Link>
                    );
                  })}
              </div>
            </ModalBody>
            <ModalFooter className="border-0">
              {language === "Türkçe" && (
                <Button
                  color="btn btn-outline-secondary"
                  onClick={() => setMenuModal(!menuModal)}
                >
                  Kapat
                </Button>
              )}
            </ModalFooter>
          </Modal>
        </>
      ) : (
        <>
          <div className="absolute top-0 left-0 w-full h-full bg-dark opacity-20"></div>

          <div className="centered z-20 mt-40">
            <h3 className="page-title text-white text-center">
              {restaurant[0]?.FIRMA}
            </h3>
            <div
              className="languageContainer tr_eng align-center items-center"
              onClick={() => setLanguageModal(true)}
            >
              <GlobeAltIcon className="w-6 h-6 mr-2 text-white" />

              <a href="#" className="text-white font-bold">
                {language === "Türkçe" && "Türkçe"}
              </a>
              <ChevronDownIcon className="w-6 h-6 mr-2 text-white ml-2" />
            </div>
            <div className="menu-container " onClick={() => setMenuModal(true)}>
              {language === "Türkçe" && (
                <button className="bg-teal-600 p-2 text-white rounded-lg">
                  Menüyü Gör
                </button>
              )}
            </div>
          </div>
          <div
            className="infoContainer z-20"
            onClick={() => setAdresModal(true)}
          >
            <div className="info-1">
              <InformationCircleIcon className="w-6 h-6  text-white" />
              {language === "Türkçe" && (
                <h6 className="text-white font-bold">Bilgi</h6>
              )}
            </div>
            <div className="info-2">
              <ChevronRightIcon className="w-6 h-6 text-white" />
            </div>
          </div>

          {/* adres modalı */}
          <Modal
            className="my-modal"
            centered
            size="md"
            isOpen={adresModal}
            toggle={() => setAdresModal(!adresModal)}
          >
            <ModalHeader toggle={() => setAdresModal(!adresModal)}>
              <p className="mt-3">Bilgi</p>
            </ModalHeader>
            <ModalBody>
              <div className="modal-container">
                <div className="address-body">
                  <p className="mt-3">{restaurant[0]?.FIRMA}</p>
                </div>
                <div className="adresContainer">
                  <p>ADRES</p>

                  <p className="text-sm">{restaurant[0]?.ADRES}</p>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="btn btn-outline-secondary"
                onClick={() => setAdresModal(!adresModal)}
              >
                Kapat
              </Button>
            </ModalFooter>
          </Modal>

          {/* language modal */}
          <Modal
            className="my-modal"
            centered
            size="md"
            isOpen={languageModal}
            toggle={() => setLanguageModal(!languageModal)}
          >
            <ModalHeader toggle={() => setLanguageModal(!languageModal)}>
              <p className="langTitle mt-3">{language}</p>
            </ModalHeader>
            <ModalBody>
              <div className="modal-container">
                <div
                  className="language-body"
                  onClick={() =>
                    chooseLanguage("Türkçe") || setLanguageModal(false)
                  }
                >
                  <img src={turkey} alt="tr" className="turkey-icon" />
                  <p className="lang">Türkçe</p>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
   
                <Button
                  color="btn btn-outline-secondary"
                  onClick={() => setLanguageModal(!languageModal)}
                >
                  Kapat
                </Button>
   
            </ModalFooter>
          </Modal>

          {/* menu modal */}
          <Modal
            className="my-modal"
            centered
            size="md"
            isOpen={menuModal}
            toggle={() => setMenuModal(!menuModal)}
          >
            <ModalHeader toggle={() => setMenuModal(!menuModal)}>
             <p className="mt-3">Menü Seçin</p>
            </ModalHeader>
            <ModalBody>
              <div className="modalmenu-container">
                {Object.keys(groupby)?.map((item, index) => {
                  return (
                    <Link to={`/home?selectedCategory=${item}`}>
                      <div
                        key={index}
                        className="border-b d-flex flex-row justify-between items-center"
                      >
                        <p className="p-1 mt-3">{item}</p>
                        <img
                          src={right}
                          className="right-logo w-5 h-5"
                          alt="img"
                        />
                      </div>
                    </Link>
                  );
                })}
              </div>
            </ModalBody>
            <ModalFooter className="border-0">
       
                <Button
                  color="btn btn-outline-secondary"
                  onClick={() => setMenuModal(!menuModal)}
                >
                  Kapat
                </Button>
     
            </ModalFooter>
          </Modal>
        </>
      )}
    </div>
  );
};

export default FirstPage;
