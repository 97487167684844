import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import React, { useRef, useState, useEffect } from "react";
import ProductList from "../components/ProductList";
import { getProduct } from "../state/productSlice";
import { LanguageContext } from "../context/LanguageContext";
import { useContext } from "react";

const Search = ({ id }) => {
  const [list, setList] = useState(null);
  const dispatch = useDispatch();
  const searchRef = useRef();
  const { product, isLoading: productLoading } = useSelector(
    (state) => state.product
  );
  const [searchResultMessage, setSearchResultMessage] = useState(""); // Arama sonucu mesajı

  useEffect(() => {
    const data1 = { path: id + "/productsno.json" };

    dispatch(getProduct(data1));
  }, [dispatch, id]);

  const handleSearch = (event) => {
    event.preventDefault();

    const searchedText = searchRef.current.value.toLowerCase();

    const filteredList = product?.filter((x) => {
      const productTitle = x.URUN_ADI?.toLowerCase();
      return productTitle.includes(searchedText);
    });

    if (filteredList && filteredList.length > 0) {
      setList(filteredList);

      if (filteredList.length > 1) {
        setSearchResultMessage(
          `"${searchedText}" için ${filteredList.length} sonuç`
        );
      } else {
        setSearchResultMessage("");
      }
    } else {
      setList(null);
      setSearchResultMessage("Ürün bulunamadı.");
    }
  };

  return (
    <div className="container-max">
      <form
        onSubmit={handleSearch}
        className="flex gap-2 md:gap-4 max-w-[560px] w-[90%] mx-auto mt-2 mb-6"
      >
        <input
          type="search"
          name="search"
          id="search"
          placeholder="Arama"
          className="p-2 px-4 rounded-md border outline-none focus-within:border-orange-400 border-gray-200 grow w-full"
          ref={searchRef}
        />
        <button
          type="submit"
          className="bg-teal-600 basis-2/12 text-center text-white p-2 flex justify-center gap-2 items-center md:px-8 rounded-md text-sm md:text-base"
        >
          <MagnifyingGlassIcon className="w-4 h-4" />{" "}
          <span className="hidden md:block">Ara</span>
        </button>
      </form>
      {searchResultMessage && (
        <p className="text-center mt-2">{searchResultMessage}</p>
      )}
      {!!list?.length && (
        <ProductList
          isLoading={productLoading}
          products={list}
          id={id}
        />
      )}
    </div>
  );
};

export default Search;
