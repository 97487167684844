import { useParams } from 'react-router-dom';
import RestaurantInfo from '../components/RestaurantInfo';
import { useSelector } from 'react-redux';

const ProductDetail = ({id}) => {

  const { code } = useParams();
  const { product, isLoading: productLoading } = useSelector(
    state => state.product
  );
  const productInfo = product.find(item => item.URUN_KOD === code);
// console.log('object', productInfo)
  return (
    <div className='container-md my-8'>
      <RestaurantInfo info={productInfo} id={id}/>
    </div>
  );
};
export default ProductDetail;
