import useGetImages from "../hooks/useGetImages";

const RestaurantInfo = ({ info, id }) => {
  const { image: uri } = useGetImages("product_pictures", id, info?.URUN_KOD);

  return (
    <div className="flex-col justify-between items-center pb-4 border-b border-dashed">
      <img
        className="w-full h-64 aspect-video object-fit rounded-md"
        src={uri}
        alt="product"
      />
      <div>
        <h2 className="text-[14px] font-bold my-2">{info.URUN_ADI}</h2>
        <p className="text-xs text-gray-500">{info.URUN_ACIKLAMA}</p>
        <p className="font-bold mt-2 text-teal-600">₺ {info.FIYATI}</p>
      </div>
    </div>
  );
};
export default RestaurantInfo;
