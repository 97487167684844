const Footer = ({ pathname }) => {
  if (pathname === '/') return null;
  return (
    <div className='container-max text-center py-4'>
      <a
        href='https://mobres.com/'
        target={'_blank'}
        className='text-teal-600 text-sm text-decoration-none'
      >
        Powered by @MobresPos
      </a>
    </div>
  );
};
export default Footer;
