import React from "react";
import "../styles/sticky.css";

const StickyCategory = ({ item, filterProducts, id, chooseCategory }) => {
  return (
    <div
      className={
        chooseCategory === item?.CATEGORY
          ? "choose_categpry_card"
          : "category_card"
      }
      onClick={() => filterProducts(item?.CATEGORY)}
    >
      <p
        className={
          chooseCategory === item?.CATEGORY
            ? "font-bold text-[12px] m-1 text-center text-white"
            : "font-bold text-[12px] m-1 text-center text-teal-600"
        }
        onClick={() => filterProducts(item?.CATEGORY)}
      >
        {item?.CATEGORY}
      </p>
    </div>
  );
};

export default StickyCategory;
