import useGetImages from "../hooks/useGetImages";

const CategoryItem = ({ item, filterProducts, id, chooseCategory }) => {
  const { image: uri } = useGetImages("category_pictures", id, item?.CATEGORY);

  return (
    <div
      className="w-48 keen-slider__slide"
      onClick={() => filterProducts(item?.CATEGORY)}
    >
      <img src={uri} className="w-full h-20 rounded-md" alt={item?.CATEGORY} />
      <p
        className={
          chooseCategory === item?.CATEGORY
            ? "mt-2 font-bold text-sm text-center text-teal-600"
            : "mt-2 font-bold text-sm text-center text-zinc-500"
        }
      >
        {item?.CATEGORY}
      </p>
    </div>
  );
};
export default CategoryItem;
