import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MIKOM_API } from "../utils/constants";

export const getProduct = createAsyncThunk(
  "product/getProduct",
  async (data) => {
    try {
      const response = await fetch(MIKOM_API, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Accept: "application/json",
        },
      });
      const productResponse = await response.json();
      // console.log("productResponse",productResponse);
      return productResponse;
    } catch (error) {
      console.log("product thunk hatası " + error);
    }
  }
);

export const productSlice = createSlice({
  name: "products",
  initialState: {
    product: [],
    isLoading: false,
  },
  // extraReducers: {
  //   [getProduct.pending]: state => {
  //     state.isLoading = true;
  //   },
  //   [getProduct.fulfilled]: (state, action) => {
  //     state.product = action.payload;
  //     state.isLoading = false;
  //   },
  //   [getProduct.rejected]: state => {
  //     state.isLoading = false;
  //   }
  // }
  extraReducers: (builder) => {
    builder
      .addCase(getProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        state.product = action.payload;
        state.isLoading = false;
      })
      .addCase(getProduct.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default productSlice;
