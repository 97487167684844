import ProductCard from "./ProductCard";
import ShimmerCard from "./ShimmerCard";
import React from "react";
import BannerList from "./BannerList";

const ProductList = ({ isLoading, products, id, language }) => {
  const groupByCategory = (data) => {
    const groupedData = {};
    data.forEach((item) => {
      const category = item.CATEGORY;
      if (!groupedData[category]) {
        groupedData[category] = [];
      }
      groupedData[category].push(item);
    });
    return groupedData;
  };

  const groupedData = groupByCategory(products);

  return (
    <div className="container-max">
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-8 ">
        {isLoading ? (
          Array.from({ length: 15 }).map((_, i) => <ShimmerCard key={i} />)
        ) : products && products?.length !== 0 ? (
          Object.keys(groupedData).map((category) => (
            <div key={category} id={category}>
              <BannerList category={category} id={id} />
              {/* <h2 className='font-bold text-[12px] text-teal-600'>{category}</h2> */}
              {groupedData[category].map((item) => (
                <ProductCard
                  key={item.id}
                  id={id}
                  item={item}
                />
              ))}
            </div>
          ))
        ) : (
          <p>Ürün Bulunamadı!</p>
        )}
      </div>
    </div>
  );
};

export default ProductList;
