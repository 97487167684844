import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState, useRef, useContext } from "react";
import CategoryList from "./CategoryList";
import ProductList from "./ProductList";
import { useDispatch, useSelector } from "react-redux";
import { getCategory } from "../state/categorySlice";
import { getProduct } from "../state/productSlice";
import { getRestaurant } from "../state/restaurantSlice";
import { useLocation, Link } from "react-router-dom";
import down from "../assets/icon/down.png";
import { LanguageContext } from "../context/LanguageContext";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import right from "../assets/icon/right.png";
import "../styles/backtotop.css";

const Body = ({ id }) => {
  const { language } = useContext(LanguageContext);
  const dispatch = useDispatch();
  const { product, isLoading: productLoading } = useSelector(
    (state) => state.product
  );
  const location = useLocation();
  const upCategory = new URLSearchParams(location.search).get(
    "selectedCategory"
  );
  const { category, isLoading } = useSelector((state) => state.category);
  const serachRef = useRef();

  // console.log('category',category)

  const [chooseCategory, setChooseCategory] = useState();
  const [visible, setVisible] = useState(false);
  const [menuModal, setMenuModal] = useState(false);

  useEffect(() => {
    const data1 = { path: id + "/productsno.json" };
    const data2 = { path: id + "/categoryno.json" };
    const data = { path: id + "/restaurant.json" };

    dispatch(getProduct(data1));
    dispatch(getCategory(data2));
    dispatch(getRestaurant(data));
  }, [dispatch, id]);

  const filterProducts = (category) => {
    setChooseCategory(category);
    document.getElementById(category).scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    });
    const handleScroll = () => {
      const scrolledElement = document.elementFromPoint(
        window.innerWidth / 2,
        window.innerHeight / 2
      );
      if (scrolledElement && scrolledElement.id) {
        const category = scrolledElement.id;
        setChooseCategory(category);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const groupby = category?.reduce((group, category) => {
    const { UP_CATEGORY_TR } = category;
    if (language === "Türkçe") {
      group[UP_CATEGORY_TR] = group[UP_CATEGORY_TR] ?? [];
      group[UP_CATEGORY_TR].push(category);
    }
    return group;
  }, {});

  const getSubCategories = (upperCategory) => {
    if (language === "Türkçe") {
      const subCategories = Object.values(groupby).find(
        (categories) => categories[0].UP_CATEGORY_TR === upperCategory
      );
      return subCategories ? subCategories : [];
    }
  };

  const subCategoriesProducts = category
    .filter((category) => category?.UP_CATEGORY_TR === upCategory)
    .map((subCategory) =>
      product.filter((product) => product.CATEGORY === subCategory.CATEGORY)
    )
    .flat();

  const subCategoriesEnProducts = category
    .filter((category) => category?.UP_CATEGORY_IN === upCategory)
    .map((subCategory) =>
      product.filter((product) => product.CATEGORY === subCategory.CATEGORY)
    )
    .flat();

  const backtotop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", () => {
      if (window.scrollY > 500) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    });
  }, []);

  return (
    <div className="bg-white relative py-2">
      {/* up category */}
      <div
        onClick={() => setMenuModal(true)}
        className="d-flex flex-row justify-center items-center mt-2"
      >
        <p className="font-bold text-xl text-zinc-600">{upCategory}</p>
        <img src={down} alt="img" className="w-6 h-6 ml-2 mb-3" />
      </div>

      {/* search bar */}
      <Link
        to={`/search`}
        className="flex gap-2 md:gap-4 max-w-[560px] w-[90%] mx-auto mt-2 mb-6 no-underline"
      >
        <input
          type="search"
          name="search"
          id="search"
          placeholder="Arama"
          className="p-2 px-4 rounded-md border outline-none focus-within:border-orange-400 border-gray-200 grow w-full"
          ref={serachRef}
        />
        <button className="bg-teal-600 basis-2/12 text-center text-white p-2 flex justify-center gap-2 items-center md:px-8 rounded-md text-sm md:text-base">
          <MagnifyingGlassIcon className="w-4 h-4" />{" "}
          <span className="hidden md:block">Ara</span>
        </button>
      </Link>

      {/* Category list */}
      <CategoryList
        category={getSubCategories(upCategory)}
        isLoading={isLoading}
        filterProducts={filterProducts}
        id={id}
        chooseCategory={chooseCategory}
        visible={visible}
        language={language}
      />

      <ProductList
        language={language}
        isLoading={productLoading}
        // products={!!list?.length ? list : subCategoriesProducts}
        products={subCategoriesProducts}
        id={id}
      />

      {visible && (
        <button className="backtotop" onClick={backtotop}>
          <i className="fa-solid fa-circle-arrow-up fixed bottom-10 right-10"></i>
        </button>
      )}

      {/* modal menu */}
      <Modal
        className="my-modal"
        centered
        size="md"
        isOpen={menuModal}
        toggle={() => setMenuModal(!menuModal)}
      >
        <ModalHeader toggle={() => setMenuModal(!menuModal)}>
          {language === "Türkçe" ? <p>Menü Seçin</p> : <p>Select a Menu</p>}
        </ModalHeader>
        <ModalBody>
          <div
            className="modalmenu-container"
            onClick={() => setMenuModal(false)}
          >
            {Object.keys(groupby)?.map((item, index) => {
              return (
                <Link to={`/home?selectedCategory=${item}`}>
                  <div
                    key={index}
                    className="border-b d-flex flex-row justify-between items-center"
                  >
                    <p className="p-1 mt-3">{item}</p>
                    <img src={right} className="right-logo w-6 h-6" alt="img" />
                  </div>
                </Link>
              );
            })}
          </div>
        </ModalBody>
        <ModalFooter className="border-0">
          {language === "Türkçe" ? (
            <Button
              color="btn btn-outline-secondary"
              onClick={() => setMenuModal(!menuModal)}
            >
              Kapat
            </Button>
          ) : (
            <Button
              color="btn btn-outline-secondary"
              onClick={() => setMenuModal(!menuModal)}
            >
              Close
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default Body;
