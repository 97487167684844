import "keen-slider/keen-slider.min.css";
import CategoryItem from "./CategoryItem";
import { useState, useRef, useEffect } from "react";
import ShimmerBanner from "./shimmers/ShimmerBanner";
import { Swiper, SwiperSlide } from "swiper/react";
import StickyCategory from "./StickyCategory";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { useKeenSlider } from "keen-slider/react";

const CategoryList = ({
  category,
  filterProducts,
  id,
  isLoading,
  chooseCategory,
  visible,
  language
}) => {
  const swiperRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderRef, instanceRef] = useKeenSlider({
    mode: "free-snap",
    renderMode: "performance",
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    initial: 0,
    breakpoints: {
      "(max-width: 480px)": {
        slides: { perView: 3, spacing: 10 },
      },
      "(min-width: 480px)": {
        slides: { perView: 6, spacing: 10 },
      },
      "(min-width: 768px)": {
        slides: { perView: 8, spacing: 10 },
      },
    },
  });
  useEffect(() => {
    instanceRef?.current?.update();
  }, [category]);

  useEffect(() => {
    const categoryIndex = category.findIndex(
      (num) => num.CATEGORY === chooseCategory 
      
    );

    swiperRef?.current?.swiper?.slideTo(categoryIndex);
  }, [chooseCategory]);

  if (isLoading)
    return (
      <div className="flex gap-4 md:gap-8 mb-4 mt-4 p-2">
        {Array.from({ length: 3 }).map((_, i) => (
          <ShimmerBanner key={i} />
        ))}
      </div>
    );
  if (!category || category?.length === 1) return null;

  return (
    <div className="container-max my-3 mt-8">
      {visible ? (
        <div className="w-full left-0 top-0 z-50 fixed bg-white p-3">
          <Swiper
            ref={swiperRef}
            slidesPerView={2}
            spaceBetween={10}
            pagination={false}
            modules={[Pagination]}
            className="mySwiper"
          >
            {category?.map((item, index) => (
              <SwiperSlide key={index}>
                <StickyCategory
                  item={item}
                  filterProducts={filterProducts}
                  id={id}
                  chooseCategory={chooseCategory}
                
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      ) : (
        <div ref={sliderRef} className="keen-slider">
          {category?.map((item, index) => (
            <CategoryItem
              item={item}
              key={index}
              filterProducts={filterProducts}
              id={id}
              chooseCategory={chooseCategory}
           
            />
          ))}
        </div>
      )}
    </div>
  );
};
export default CategoryList;
