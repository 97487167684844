import {
  Bars3Icon,
  HomeIcon,
  XMarkIcon,
  ArrowLeftIcon,
} from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toggleMenu } from "../features/app/appSlice";
import React, { useState, useContext,useEffect } from "react";
import turkey from "../assets/icon/turkey.png";
import { LanguageContext } from "../context/LanguageContext";
import down from "../assets/icon/down.png";
import "../styles/header.css";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { GlobeAltIcon } from "@heroicons/react/24/solid";

const Header = ({ pathname,id }) => {
  const { isMenuOpen } = useSelector((state) => state.app);
  const { language, chooseLanguage } = useContext(LanguageContext);
  const dispatch = useDispatch();
  const [languageModal, setLanguageModal] = useState(false);
  const handleToggleMenu = () => dispatch(toggleMenu());
  const { restaurant, isLoading: restaurantLoading } = useSelector(
    (state) => state.restaurant
  );
  const navigate = useNavigate();
  const handleClick = (event) => {
    event.preventDefault();
    navigate(-1);
  };
  const [resim1, setResim1] = useState("");
  const url =
  "https://1zw31qczi7.execute-api.eu-central-1.amazonaws.com/prod/restaurant_pictures/" +
  id +
  "/";

useEffect(() => {
  if (id) {
    fetch(url + "1").then((data) => {
      data.text().then((text) => setResim1(text));
    });
  }
}, [id]);

  if (pathname === "/" && !!resim1) return null;


  return (
    <header
      className={
        pathname === "/"
          ? "sticky w-full top-0 z-20 bg-teal-600 py-4 border-b shadow-sm border-gray-100"
          : "sticky w-full top-0 z-20 bg-teal-600 py-1 border-b shadow-sm border-gray-100"
      }
    >
      <div className="container-max flex justify-between items-center">
        {isMenuOpen && (
          <div className="shadow-lg transition-all md:hidden absolute top-full right-0 bg-white h-screen py-4 w-80">
            <>
              <ul className="text-zinc-700 space-y-4">
                <li>
                  <div
                    className="px-1 hover:bg-gray-50 rounded-md flex items-center gap-2"
                    onClick={() => setLanguageModal(true)}
                  >
                    <GlobeAltIcon className="w-6 h-6 mr-2" />

                    <a
                      href="#"
                      className="langu text-dark-600 font-bold no-underline"
                    >
                      {language}
                    </a>
                    <img src={down} alt="img" className="w-6 h-6 ml-2" />
                  </div>
                </li>
                <li>
                  <div className="px-1 hover:bg-gray-50 rounded-md flex items-center gap-2 no-underline mt-3">
                    <HomeIcon className="w-6 h-6  text-[#31363F] mb-1" />
                    <Link
                      to="/"
                      className="langu text-dark-600 font-bold no-underline ml-3"
                    >
                      {language === "Türkçe" ? "Ana Sayfa" : "Home Page"}
                    </Link>
                  </div>
                </li>
              </ul>
              <div className="flex flex-col justify-center items-center absolute bottom-20">
                <p className="text-sm ml-10">{restaurant[0]?.ADRES}</p>
              </div>
            </>
          </div>
        )}
        {pathname !== "/" && (
          <>
            <Link onClick={handleClick}>
              <ArrowLeftIcon className="w-6 h-6 text-white" />
            </Link>

            <p className="text-white mt-3">{restaurant?.[0]?.FIRMA}</p>
            {!isMenuOpen ? (
              <button className="block md:hidden" onClick={handleToggleMenu}>
                <Bars3Icon className="w-6 h-6 text-white" />
              </button>
            ) : (
              <button className="block md:hidden" onClick={handleToggleMenu}>
                <XMarkIcon className="w-6 h-6 text-white" />
              </button>
            )}
          </>
        )}
      </div>

      {/* language modal */}
      <Modal
        className="my-modal"
        centered
        size="md"
        isOpen={languageModal}
        toggle={() => setLanguageModal(!languageModal)}
      >
        <ModalHeader toggle={() => setLanguageModal(!languageModal)}>
          <p className="langTitle mt-3">{language}</p>
        </ModalHeader>
        <ModalBody>
          <div className="modal-container">
            <div
              className="language-body"
              onClick={() =>
                chooseLanguage("Türkçe") || setLanguageModal(false)
              }
            >
              <img src={turkey} alt="tr" className="detailMenu-icon" />
              <p className="lang">Türkçe</p>
            </div>
            {/* <div
              className="language-body"
              onClick={() =>
                chooseLanguage("English") || setLanguageModal(false)
              }
            >
              <img src={english} alt="tr" className="detailMenu-icon" />
              <p className="lang">English</p>
            </div> */}
          </div>
        </ModalBody>
        <ModalFooter>
          {language === "Türkçe" ? (
            <Button
              color="btn btn-outline-secondary"
              onClick={() => setLanguageModal(!languageModal)}
            >
              Kapat
            </Button>
          ) : (
            <Button
              color="btn btn-outline-secondary"
              onClick={() => setLanguageModal(!languageModal)}
            >
              Close
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </header>
  );
};
export default Header;
