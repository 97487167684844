import { configureStore } from '@reduxjs/toolkit';
import appReducer from '../features/app/appSlice';
import productSlice from '../state/productSlice';
import categorySlice from '../state/categorySlice';
import restaurantSlice from '../state/restaurantSlice';

const store = configureStore({
  reducer: {
    app: appReducer,
    category: categorySlice.reducer,
    product: productSlice.reducer,
    restaurant: restaurantSlice.reducer
  }
});

export default store;
