import { Link } from "react-router-dom";
import useGetImages from "../hooks/useGetImages";
import React from "react";

const ProductCard = ({ item, id }) => {
  const { image: uri } = useGetImages("product_pictures", id, item?.URUN_KOD);
  // console.log('item', item)
  if (!item) return null;

  return (
    <Link
      to={`/product/${item?.URUN_KOD}`}
      className="flex bg-white rounded-lg shadow-lg overflow-hidden px-2 py-3 my-2 no-underline"
    >
      <div className="w-2/4">
        <h2 className="text-[14px] font-semibold text-zinc-700">
          {item?.URUN_ADI}
        </h2>
        <p className="text-xs text-zinc-700">{item?.URUN_ACIKLAMA}</p>

        <p className="font-bold mt-2 text-teal-600">₺ {item?.FIYATI}</p>
      </div>
      <div className="w-2/4 ml-1">
      {!!uri && (
          <img
            src={uri}
            alt={item?.URUN_ADI}
            className="object-fill h-32 w-48 rounded-md"
          />
        )}
      
      </div>
    </Link>
  );
};

export default ProductCard;
